<div class="services" autoscroll="true">
  <div class="header-main">
    <div class="header-main__text">
      <h2 i18n="@@*span*Elevating Your Business*span* with our *span*Range of Services*span*">
        <span>Elevating Your Business</span>
        <br />
        with our <span>Range of Services</span>
      </h2>
      <div>
        <p class="l normal" i18n="@@Discover our comprehensive range of services designed to meet your every need. From custom software development to IT consulting, our solutions are crafted with precision and expertise to drive your business forward in the digital age.">Discover our comprehensive range of services designed to meet your every need. From custom software development to IT consulting, our solutions are crafted with precision and expertise to drive your business forward in the digital age.<br></p>
      </div>
    </div>
    <div class="background">
      <div>
        <h1 i18n="@@Services">
          Services
        </h1>
      </div>
    </div>
  </div>
  <div class="services-list">

    <div class="service-block">
      <div class="description-block">
        <div class="service-info">
          <div class="title">
            <div class="text__image">
              <mat-icon svgIcon="software-dev"></mat-icon>
            </div>
            <h5 i18n="@@Software Development" routerLink="/services/service-development">Software Development</h5>
          </div>
          <p class="l normal description" i18n="@@Custom software development services tailored to various industries and business needs. Our team of experts provides full-cycle development, from concept and design to testing and support.">Custom software development services tailored to various industries and business needs. Our team of experts provides full-cycle development, from concept and design to testing and support.</p>
        </div>
        <div class="service-list">
          <ul class="custom">
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Application Architecture Design">Application Architecture Design</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Application Development">Application Development</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Testing">Testing</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Setting up CI/CD and Application Deployment">Setting up CI/CD and Application Deployment</p>
            </li>
          </ul>
        </div>
      </div>
      <button routerLink="/services/service-development" class="button-icon">
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
    </div>

    <div class="service-block">
      <div class="description-block">
        <div class="service-info">
          <div class="title">
            <div class="text__image">
              <mat-icon svgIcon="mobile-app"></mat-icon>
            </div>
            <h5 i18n="@@Mobile App Development" routerLink="/services/mobile-app-development">Mobile App Development</h5>
          </div>
          <p class="l normal description" i18n="@@Creation of iOS and Android mobile applications that deliver high performance, user-friendliness, and attractive design. Our team of developers ensures full support from idea to app store release.">Creation of iOS and Android mobile applications that deliver high performance, user-friendliness, and attractive design. Our team of developers ensures full support from idea to app store release.</p>
        </div>
        <div class="service-list">
          <ul class="custom">
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Creating Cross-Platform Application Architecture for Selected OS">Creating Cross-Platform Application Architecture for Selected OS</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Application Development">Application Development</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Testing">Testing</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Setting up CI/CD and Application Deployment">Setting up CI/CD and Application Deployment</p>
            </li>
          </ul>
        </div>
      </div>
      <button routerLink="/services/mobile-app-development" class="button-icon">
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
    </div>

    <div class="service-block">
      <div class="description-block">
        <div class="service-info">
          <div class="title">
            <div class="text__image">
              <mat-icon svgIcon="machine-learning"></mat-icon>
            </div>
            <h5 i18n="@@Machine Learning & Artificial Intelligence integration" routerLink="/services/MA-AII">Machine Learning & Artificial Intelligence integration</h5>
          </div>
          <p class="l normal description" i18n="@@Integration of machine learning and artificial intelligence technologies into software and business processes. We help organizations leverage data for task automation, trend forecasting, and decision-making enhancement.">Integration of machine learning and artificial intelligence technologies into software and business processes. We help organizations leverage data for task automation, trend forecasting, and decision-making enhancement.</p>
        </div>
        <div class="service-list">
          <ul class="custom">
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Creating Solution Architecture">Creating Solution Architecture</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Solution Implementation">Solution Implementation</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Testing">Testing</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Setting up CI/CD and Application Deployment">Setting up CI/CD and Application Deployment</p>
            </li>
          </ul>
        </div>
      </div>
      <button routerLink="/services/MA-AII" class="button-icon">
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
    </div>

    <div class="service-block">
      <div class="description-block">
        <div class="service-info">
          <div class="title">
            <div class="text__image">
              <mat-icon svgIcon="design"></mat-icon>
            </div>
            <h5 i18n="@@UX & UI Design" routerLink="/services/UX-UI">UX & UI Design</h5>
          </div>
          <p class="l normal description" i18n="@@User experience (UX) and user interface (UI) design for websites, mobile apps, and other digital products. Our design team aims to  create aesthetically pleasing and intuitive interfaces, considering user needs and business goals.">User experience (UX) and user interface (UI) design for websites, mobile apps, and other digital products. Our design team aims to create aesthetically pleasing and intuitive interfaces, considering user needs and business goals.</p>
        </div>

        <div class="service-list">
          <ul class="custom">
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Requirements Analysis">Requirements Analysis</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Providing Prototypes">Providing Prototypes</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Design Development">Design Development</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Preparing Adaptives and Handoff to Development">Preparing Adaptives and Handoff to Development</p>
            </li>
          </ul>
        </div>
      </div>
      <button routerLink="/services/UX-UI" class="button-icon">
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
    </div>

    <div class="service-block">
      <div class="description-block">
        <div class="service-info">
          <div class="title">
            <div class="text__image">
              <mat-icon svgIcon="business-analysis"></mat-icon>
            </div>
            <h5 i18n="@@Business Analysis" routerLink="/services/business-analysis">Business Analysis</h5>
          </div>
          <p class="l normal description" i18n="@@Analysis of business processes and requirements for the development of software solutions that align with company needs and strategy. Our team of analysts works to identify optimization opportunities, improve efficiency, and achieve business goals.">Analysis of business processes and requirements for the development of software solutions that align with company needs and strategy. Our team of analysts works to identify optimization opportunities, improve efficiency, and achieve business goals.</p>
        </div>

        <div class="service-list">
          <ul class="custom">
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Studying Client Requirements">Studying Client Requirements</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Preparing Application Concept">Preparing Application Concept</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Creating Technical Specifications">Creating Technical Specifications</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Describing Business Processes">Describing Business Processes</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Analyzing Technical Capabilities">Analyzing Technical Capabilities</p>
            </li>
            <li class="point-block">
              <p class="service-custom l bold" i18n="@@Preparing Technical Documentation for the Developed Application">Preparing Technical Documentation for the Developed Application</p>
            </li>
          </ul>
        </div>
      </div>
      <button routerLink="/services/business-analysis" class="button-icon">
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
    </div>

  </div>
  <div class="projects">
    <app-projects [projects]="projects">
      <div class="all-projects">
        <h2 i18n="@@Our projects">Our projects</h2>
        <p class="l normal" i18n="@@Explore our diverse portfolio showcasing projects of different scales and industries, highlighting our adaptability and expertise.">Explore our diverse portfolio showcasing projects of different scales and industries, highlighting our adaptability and expertise.<br></p>
      </div>
      <div class="slider">
        <button class="button-link" routerLink="/projects" i18n="@@All projects *icon*">All projects<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
        <div class="action__slider">
          <button class="button-icon prev-slide">
            <mat-icon class="small rotate" svgIcon="arrow-right"></mat-icon>
          </button>
          <button class="button-icon next-slide">
            <mat-icon class="small" svgIcon="arrow-right"></mat-icon>
          </button>
        </div>
      </div>
    </app-projects>
  </div>
  <div class="industries">
    <h2 i18n="@@Explore Our Industries">Explore Our <br />Industries</h2>
    <div class="industries-container">

      <div class="industry-block border-custom middle" style="border-color: var(--orange)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@Finance & Banking" routerLink="/industries/finance">Finance & Banking</h3>
          </div>
          <p class="l normal text" i18n="@@Discover how our innovative technology solutions are reshaping the finance and banking sector. From streamlined digital banking experiences to advanced risk management systems, we empower financial institutions to thrive in the digital age.">Discover how our innovative technology solutions are reshaping the finance and banking sector. From streamlined digital banking experiences to advanced risk management systems, we empower financial institutions to thrive in the digital age.<br></p>
        </div>

        <button routerLink="/industries/finance" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--olive)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@Healthcare & Life Sciences" routerLink="/industries/healthcare">Healthcare & Life Sciences</h3>
          </div>
          <p class="l normal text" i18n="@@Empower your healthcare organization with cutting-edge technology solutions tailored for the Healthcare & Life Sciences industry, enabling seamless patient care, data management, and research advancement.">Empower your healthcare organization with cutting-edge technology solutions tailored for the Healthcare & Life Sciences industry, enabling seamless patient care, data management, and research advancement.<br></p>
        </div>

        <button routerLink="/industries/healthcare" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--red)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@eCommerce & Retail" routerLink="/industries/ecommerce">eCommerce & Retail</h3>
          </div>
          <p class="l normal text" i18n="@@Elevate your eCommerce & Retail business with innovative digital solutions that enhance customer experience, streamline operations, and drive sales growth in the competitive online marketplace.">Elevate your eCommerce & Retail business with innovative digital solutions that enhance customer experience, streamline operations, and drive sales growth in the competitive online marketplace.</p>
        </div>

        <button routerLink="/industries/ecommerce" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--blue)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@Hi-Tech & Innovations" routerLink="/industries/innovations">Hi-Tech & Innovations</h3>
          </div>
          <p class="l normal text" i18n="@@Stay ahead of the curve in the fast-paced world of Hi-Tech & Innovations with our custom solutions, designed to fuel your innovation initiatives, accelerate product development, and drive technological advancements.">Stay ahead of the curve in the fast-paced world of Hi-Tech & Innovations with our custom solutions, designed to fuel your innovation initiatives, accelerate product development, and drive technological advancements.</p>
        </div>

        <button routerLink="/industries/innovations" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--green)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@eLearning & Education" routerLink="/industries/education">eLearning & Education</h3>
          </div>
          <p class="l normal text" i18n="@@Transform the future of education with our eLearning & Education solutions, providing interactive platforms, personalized learning experiences, and data-driven insights to enhance student engagement and outcomes.">Transform the future of education with our eLearning & Education solutions, providing interactive platforms, personalized learning experiences, and data-driven insights to enhance student engagement and outcomes.</p>
        </div>

        <button routerLink="/industries/education" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--red)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@Media & Entertainment">Media & Entertainment</h3>
          </div>
          <p class="l normal text" i18n="@@Engage audiences and captivate viewers with our Media & Entertainment solutions, offering immersive digital experiences, content management systems, and analytics tools to drive content creation, distribution, and monetization strategies.">Engage audiences and captivate viewers with our Media & Entertainment solutions, offering immersive digital experiences, content management systems, and analytics tools to drive content creation, distribution, and monetization strategies.</p>
        </div>

        <button routerLink="/industries/media" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

      <div class="industry-block border-custom middle" style="border-color: var(--orange)">
        <div class="industry-info">
          <div class="title">
            <h3 i18n="@@Logistics & Transportation" routerLink="/industries/logistics">Logistics & Transportation</h3>
          </div>
          <p class="l normal text" i18n="@@Optimize your supply chain and transportation networks with our Logistics & Transportation solutions, delivering real-time visibility, operational efficiency, and cost savings across the entire logistics ecosystem.">Optimize your supply chain and transportation networks with our Logistics & Transportation solutions, delivering real-time visibility, operational efficiency, and cost savings across the entire logistics ecosystem.</p>
        </div>

        <button routerLink="/industries/logistics" class="button-link" i18n="@@Explore *icon*">Explore<mat-icon class="small" svgIcon="arrow-up-right-bold"></mat-icon></button>
      </div>

    </div>
  </div>
  <div class="contact-us">
    <app-contact-us></app-contact-us>
  </div>
</div>
