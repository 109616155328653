import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ContactUsComponent } from '@components/contact-us/contact-us.component';
import { SERVICES } from './services.const';
import { ProjectsComponent } from '@components/projects/projects.component';
import { INDUSTRIES } from '@const/industries.const';
import { RouterModule } from '@angular/router';
import { Industries } from '@interfaces/industries.interface';
import { DataService } from 'src/app/services/data.service';
import { Observable } from 'rxjs';
import { Project } from '@interfaces/project.interface';

@Component({
  selector: 'app-services',
  standalone: true,
  imports: [MatIcon, ContactUsComponent, ProjectsComponent, RouterModule],
  templateUrl: './services.component.html',
  styleUrl: './services.component.scss',
})
export class ServicesComponent implements OnInit {
  public projects: Observable<Project[]> | null = null;
  public services: any = SERVICES;
  public industries: Industries[] = INDUSTRIES;

  constructor(private dataService: DataService) {}

  ngOnInit():void {
    this.projects = this.dataService.projects;
  }
}
